import React from "react"
import Img from "gatsby-image"
import { Link } from "gatsby"
import { rhythm } from "../utils/typography"

class Layout extends React.Component {
    render() {
        const { children, hero } = this.props
        const header = (
            <Link to={`/`}>
                {
                    hero ?
                        <Img fluid={hero.childImageSharp.fluid} alt="" style={{
                            maxHeight: `25rem`
                        }}/> :
                        <div
                            style={{
                                marginLeft: `auto`,
                                marginRight: `auto`,
                                maxWidth: rhythm(24),
                                padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
                            }}
                        >
                            <h1>gavé.cool</h1>
                        </div>
                }
            </Link>
        )

        return (
            <>
                <header>{header}</header>
                <div
                    style={{
                        marginLeft: `auto`,
                        marginRight: `auto`,
                        maxWidth: rhythm(24),
                        padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
                    }}
                >
                    <main>{children}</main>
                    <footer
                        style={{
                            marginTop: `${rhythm(1.5)}`
                        }}
                    >
                        © {new Date().getFullYear()}
                        {` `}
                        <a href="https://gavé.cool">gavé.cool</a>
                    </footer>
                </div>
            </>
        )
    }
}

export default Layout
